const links = {
  home: "/Home",
  contact: "/Contact",
  // myFeed: "/MyFeed",
  projects: "/Projects",
  resume: "/Resume",
  login: "/Login",
  updateSite: "/UpdateSite",
};

export default links;
